import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

import './style.scss';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />,
);
serviceWorkerRegistration.register();