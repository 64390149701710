import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOnline: true,
    toast: false,
    isScroll: true,
    genres: [],
    albums: [],
    artists: [],
    tracks: [],
    favorite: [],
    playlist: [],
    playlistID: false,
    activeTrack: false,
    playing: false,
    playlistShow: false,
    images: {},
    loadingImages: []
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toastSet: (state, action) => {
            const data = action.payload;
            const {status, title, text} = data; 

            state.toast = {status, title, text};
        },
        toastRemove: state => {
            state.toast = false;
        },
        infoSet: (state, action) => {
            const data = action.payload;
            state.genres = data.genres;
            state.albums = data.albums;
            state.artists = data.artists;
            state.tracks = data.tracks;
        },
        imagesSet: (state, action) => {
            const data = action.payload;
            state.images = data;
        },
        imagesAdd: (state, action) => {
            const {ID, image} = action.payload;
            if (!state.images[ID]) {
                state.images[ID] = image;
                if (state.loadingImages.includes(ID)) {
                    state.loadingImages = state.loadingImages.filter(e=>e!==ID);
                }
            }
        },
        imagesAddLoad: (state, action) => {
            const ID = action.payload;
            if (!state.images[ID] && !state.loadingImages.includes(ID)) {
                state.loadingImages.push(ID);
            }
        },
        playlistShowToggle: (state, action) => {
            state.playlistShow = action.payload !== undefined ? action.payload : !state.playlistShow;
        },
        playlistSet: (state, action) => {
            const data = action.payload;

            if (data.playlist && data.playlist.length) {
                state.playlist = data.playlist;
            }

            if (data.playlistID) {
                state.playlistID = data.playlistID;
            }

            if ((data.playlist && data.playlist.length) || (state.playing && state.playing.length)) {
                state.playing = true;
            }
            
            if (data.activeTrack) {
                state.playing = true;
                state.activeTrack = data.activeTrack;
            } else if (data.playlist && data.playlist.length) {
                state.activeTrack = data.playlist[0];
            }
        },
        playToggle: state => {
            state.playing = !state.playing;
        },
        scrollSet: (state, action) => {
            const data = action.payload;
            if (data !== undefined) {
                state.isScroll = data;
            } else {
                state.isScroll = !state.isScroll;
            }
        },
        onlineSet: (state, action) => {
            const data = action.payload;
            state.isOnline = data;
        }
    },
});

export const { toastSet, toastRemove, infoSet, imagesSet, imagesAdd, imagesAddLoad, playlistShowToggle, playlistSet, playToggle, scrollSet, onlineSet } = appSlice.actions;

export default appSlice.reducer;
