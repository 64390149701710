import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    init: true,
    authentication: false,
    name: '',
    email: '',
    favorite_albums: [],
    favorite_artists: [],
    favorite_tracks: []
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLogin: (state, action) => {
            const user = action.payload;
            console.log(user)
            state.init = false;
            state.authentication = true;
            state.name = user.name;
            state.email = user.email;
            state.favorite_albums = user.favorite_albums;
            state.favorite_artists = user.favorite_artists;
            state.favorite_tracks = user.favorite_tracks;
        },
        userLogout: state => {
            state.init = false;
            state.authentication = false;
            state.name = '';
            state.email = '';
            state.favorite_albums = [];
            state.favorite_artists = [];
            state.favorite_tracks = [];

            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
        },
        //   incrementByAmount: (state, action) => {
        //     state.value += action.payload;
        //   },
    },
});

export const { userLogin, userLogout } = userSlice.actions;

export default userSlice.reducer;
