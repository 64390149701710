import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user.js';
import appReducer from './app.js';

export default configureStore({
    reducer: {
        user: userReducer,
        app: appReducer,
    },
});
